<template>
  <div v-if="loading.candidate" class="h-100 d-flex justify-center align-center">
    <v-progress-circular :size="50" color="primary" indeterminate/>
  </div>
  <div v-else class="page">
    <div class="profile-board">
      <ProfileData :user="candidate"/>
    </div>

    <div v-if="selectedTab" class="profile-board mt-8 pt-2">
      <ProfileTabs />
      <component v-bind:is="selectedTab.component" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileData from '@/components/profile/ProfileData';
import ProfileTabs from '@/components/profile/ProfileTabs';
import GeneralInformation from '@/components/profile/tabs/GeneralInformation';
import ComplianceReport from '@/components/profile/tabs/ComplianceReport';
import Scales from '@/components/profile/tabs/Scales';
import Competencies from '@/components/profile/tabs/Competencies';
import StressTolerance from '@/components/profile/tabs/StressTolerance';
import MotivatorsAndDestructors from '@/components/profile/tabs/MotivatorsAndDestructors';
import TeamRoles from '@/components/profile/tabs/TeamRoles';
import ManagementStyle from '@/components/profile/tabs/ManagementStyleV2';
import Logic from '@/components/profile/tabs/Logic';
import ProfInterests from '@/components/profile/tabs/ProfInterests';

export default {
  name: 'Candidate',
  components: {
    ProfileData,
    ProfileTabs,
    GeneralInformation,
    ComplianceReport,
    Scales,
    Competencies,
    StressTolerance,
    MotivatorsAndDestructors,
    TeamRoles,
    ManagementStyle,
    Logic,
    ProfInterests,
  },
  computed: {
    ...mapGetters({
      selectedTab: 'profile/tabs/selectedTab',
      candidate: 'users/candidate/candidate',
      loading: 'users/candidate/loading',
    }),
  },
  methods: {
    ...mapActions({
      loadCandidate: 'users/candidate/loadCandidate',
      loadCandidateResults: 'users/candidate/loadCandidateResults',
      resetStateCandidate: 'users/candidate/resetState',
      resetStateTabs: 'profile/tabs/resetState',
    }),
  },
  created() {
    this.loadCandidate(this.$route.params.id);
    this.loadCandidateResults(this.$route.params.id);
  },
  beforeDestroy() {
    this.resetStateCandidate();
    this.resetStateTabs();
  },
};
</script>
